import { t } from '@/i18n/translation_util';

import { analyticsService } from '@/services/analytics-service';

import { taboolaTrackStartCheckout } from '@/lib/event-trackers';
import { logger, trialDays } from '@listening/shared';

export function CTAButtonTryApp({
  className,
  btnText,
  hrefOverride,
}: {
  className: string;
  btnText?: string;
  hrefOverride?: string;
}) {
  const href = hrefOverride ?? `/app/login`;

  return (
    <a
      data-cta-button
      onClick={() => {
        logger.log('CTA button clicked');
        analyticsService.trackLogCtaClicked();
        taboolaTrackStartCheckout();
      }}
      href={href}
      className={className}
    >
      {btnText ??
        t('Try the app free for x days', {
          trialDays,
        })}
    </a>
  );
}
