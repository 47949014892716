declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions -- This is hacky. Don't use elsewhere.
  interface Window {
    _tfa?: { notify: string; name: string; id: number }[];
  }
}
export const taboolaTrackStartCheckout = () => {
  if (window._tfa && Array.isArray(window._tfa)) {
    window._tfa.push({ notify: 'event', name: 'start_checkout', id: 1625839 });
  }
};
